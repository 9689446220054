import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import { useWindowSize } from "api/commonConstants";

const BlogWidget = ({ liveBlogWidgetData }) => {
  console.log("liveBlogWidgetData", liveBlogWidgetData);
  const [eventsDataList, setEventsDataList] = useState(
    liveBlogWidgetData[0]?.events
  );
  const size = useWindowSize();

  const containerRef = useRef(null);
  const maxEventsToShow = size?.width <= 499 ? 5 : eventsDataList?.length;

  useEffect(() => {
    const container = containerRef.current;

    const preventScroll = (e) => {
      // Check if the event is from scrollbar interaction
      if (e.target === container) {
        e.stopImmediatePropagation();
      }
    };

    if (container) {
      container.addEventListener("touchmove", preventScroll, {
        passive: false,
      });
      container.addEventListener("wheel", preventScroll, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener("touchmove", preventScroll);
        container.removeEventListener("wheel", preventScroll);
      }
    };
  }, []);

  const [fontSize, setFontSize] = useState("normal");

  useEffect(() => {
    console.log("eventsDataList", eventsDataList);
  }, [eventsDataList]);

  useEffect(() => {
    const socket = io(
      `${process.env.NEXT_PUBLIC_LIVEBLOG_WEBSOCKET}/${process.env.NEXT_PUBLIC_APP_SLUG}`,
      {
        transports: ["websocket"],
      }
    );

    socket.on("connect", () => {
      console.log("Connected to the Liveblog socket server");
    });
    socket.on("error", (error) => {
      console.error("Failed to connect", error);
    });

    socket.on("event:add", (data) => {
      //* check if event.blogId matches blogId
      console.log("event:add", data.result);
      if (data && data.result) {
        let result = data.result;
        if (result?.blogId === liveBlogWidgetData[0]?._id) {
          // result?.updatedAtFormated =getTimeFormat(result?.updatedAt);
          // result?.updatedAtFormated ="12";
          if (!result?.updatedAtFormated) {
            result = {
              ...result,
              updatedAtFormated: getTimeFormat(result?.updatedAt) ?? "",
            };
          }
          console.log("event after uodated", result);

          setEventsDataList((prevEvents) => [result, ...prevEvents]);
        }
      }
    });

    socket.on("event:update", (data) => {
      console.log("event:update", data.result);
      if (data && data.result) {
        let result = data.result;
        if (result?.blogId === liveBlogWidgetData[0]?._id) {
          if (!result?.updatedAtFormated) {
            result = {
              ...result,
              updatedAtFormated: getTimeFormat(result?.updatedAt) ?? "",
            };
          }
          console.log("event after uodated", result);
          console.log("event after uodated eventsData", eventsData);

          let updatedEvents = eventsData?.map((event) =>
            event._id === result._id ? result : event
          );
          console.log("updatedEvents", updatedEvents);
          setEventsDataList(updatedEvents);
        }
      }
    });

    socket.on("event:delete", (data) => {
      console.log("event:delete", data.result);
      if (data && data.result) {
        let result = data.result;
        if (result?.blogId === liveBlogWidgetData[0]?._id) {
          if (!result?.updatedAtFormated) {
            result = {
              ...result,
              updatedAtFormated: getTimeFormat(result?.updatedAt) ?? "",
            };
          }
          console.log("event after delete", result);
          console.log("event after delete", eventsData);

          let newEvents = eventsData?.filter(
            (event) => event._id !== result._id
          );
          console.log("newEvents", newEvents);
          setEventsDataList(newEvents);
        }
      }
    });
    socket.on("liveblog:update", (data) => {
      // console.log("event:delete", data.result, blogId);
      if (data && data.result) {
        let result = data.result;
        if (result?._id === props.eventsData.entities[0].blogId) {
          setBlogTitle(result?.title);
          setBlogDescription(result?.summaryPlainText);
          setHeaderImage(result?.thumbnail);
          setHeaderImgCaption(result?.imageCaption);
          console.log("headerImage 00", result);
        }
      }
    });

    // Cleanup on component unmount
    return () => {
      socket?.disconnect();
    };
  }, []);
  console.log("BlogWidgetasd", liveBlogWidgetData);
  return (
    <>
      {eventsDataList?.length > 2 && (
        <div className="widget-blog-outer">
          <div className="widget-header">
            <img src="/assets/images/live_gif.gif" alt="live-blog-icon" />
            <span> {liveBlogWidgetData[0]?.title}</span>
          </div>
          <div className="header-seperator"></div>

          {/* <div className="curved-head"></div> */}
          <div className="widget-body-outer">
            <div className="scrollbar-seperator"></div>

            <div className="widget-body" ref={containerRef}>
              <div className="events-mobile widget-events-movbile">
                {eventsDataList
                  .slice(0, maxEventsToShow)
                  .map((event, index) => {
                    // console.log("eventsDataeventsData", eventsDataList?.updatedAtFormated);
                    var time = event?.publishedAtFormated;
                    var timeLeft = "";
                    var timeRight = "";
                    if (time?.includes("AM")) {
                      time = time?.split("AM");
                      timeLeft = time[0] + "AM";
                      timeRight = time[1];
                    } else if (time?.includes("PM")) {
                      time = time?.split("PM");
                      timeLeft = time[0] + "PM";
                      timeRight = time[1];
                    }
                    console.log("timeLeft 0", time);

                    console.log("timeLeft 1", timeLeft);
                    console.log("timeLeft 2", timeRight);

                    return (
                      <>
                        <div
                          key={index}
                          style={
                            index === 0
                              ? {
                                  display: "flex",
                                  gap: "10px",
                                  // justifyContent: "space-evenly",
                                  // borderBottom: "1px solid rgb(0 0 0 / 9%)",
                                  // paddingBottom: "15px",
                                }
                              : {
                                  display: "flex",
                                  gap: "10px",
                                  // justifyContent: "space-evenly",
                                  // borderBottom: "1px solid rgb(0 0 0 / 9%)",
                                  marginTop: "12px",
                                  // paddingBottom: "15px",
                                }
                          }
                        >
                          <div
                            className="event-left"
                            style={{ marginLeft: "22px", width: "2.5%" }}
                          >
                            <div className="event-left-iicon-outer">
                              <div></div>
                            </div>
                          </div>
                          <div className="event-right">
                            <div style={{ display: "flex", fontSize: "12px" }}>
                              {timeLeft}
                              <div className="pink-small-dot"></div> {timeRight}
                            </div>
                            <a
                              // href={`${liveBlogWidgetData[0]?.permalink}#${event?._id}`}
                              href={`/${liveBlogWidgetData[0]?.categorySlug}/post/${liveBlogWidgetData[0]?.postSlug}`}
                            >
                              <h3
                                className={
                                  fontSize == "small"
                                    ? "event-right-title blog-smal-title"
                                    : fontSize == "normal"
                                    ? "event-right-title blog-normal-title"
                                    : "event-right-title blog-large-title"
                                }
                                style={{
                                  fontSize: "16px !important",
                                  margin: "4px 0 !important",
                                  color: "black",
                                }}
                              >
                                {event?.title}
                              </h3>
                            </a>

                            {/* <div
                        className={
                          fontSize == "small"
                            ? "event-right-content blog-small"
                            : fontSize == "normal"
                            ? "event-right-content blog-normal"
                            : "event-right-content blog-large"
                        }
                      >
                        {HtmlParser(event?.articleHTML)}
                      </div> */}
                          </div>
                          {/* <div className="event-share">
                <img src="/assets/images/share2.png" width="20px"></img>
              </div> */}
                        </div>
                        {(size.width >= 499 ||
                          (size.width < 499 && index < 2)) && (
                          <div className="horizontal-line-liveblog-widget"></div>
                        )}
                      </>
                    );
                  })}
                <div className="vertical-line"></div>
              </div>
            </div>
          </div>

          <a href={`${liveBlogWidgetData[0]?.permalink}`}>
            <div className="show-all-button">
              <div className="widget-see-all">
                <div className="widget-text">All Live Update</div>
                <i class="icon-arrow-right"></i>
              </div>
            </div>
          </a>
        </div>
      )}
    </>
  );
};
export default BlogWidget;
